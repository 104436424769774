import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class MatraszentimrePage extends Component {
  componentDidMount() {
    //
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak - Mátraszentimre</title>
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="matraszentimre">
                    <h1>Mátraszentimre</h1>

                    <h2>Története</h2>

                    <p>
                      A kis hegyi falvak eredetéről a mai napig megoszlanak a vélemények, az egyik
                      nézet szerint német, a másik szerint döntően szlovák eredetű népesség élt itt.
                      Vélhetően mind a két népcsoportból érkeztek ide betelepülők. Az első telepesek
                      üvegfúvással kezdtek foglalkozni, amelyhez a környező erdők biztosították az
                      alapanyagot. Ennek állít emléket a mátraszentimrei üveghuta. Erre utal régi
                      nevük: Ötházhuta-Mátraszentimre, Fiskalitáshuta-Mátraszentlászló,
                      Felsőhuta-Mátraszentistván. Az első családok leszármazottai a ma is a
                      falvakban élő Gubalák, Stullerek, Szabadok, Randliszekek, Szőkék. Ma is az
                      eredeti szlovák nevek vannak többségben, hiszen a helyben élő őslakosokhoz
                      beházasodottak hamar asszimilálódtak.
                    </p>

                    <p>
                      A Felső-Mátra, vagy ahogyan másképpen nevezik: a Nyugati-Mátra hosszú időn át
                      ismeretlen volt a nagyközönség számára. Az 1900-as évek elején megjelent
                      &quot;Mátrai és gyöngyösi kalauz&quot; című munka szemléletesen mutatja be a
                      településeket és az itt élő embereket:
                    </p>

                    <p>
                      &quot;Galyát magas fekvésű, szénégetőktől lakott hutáknak nevezett kis
                      községek környezik északról és nyugatról, amelyek magukban véve is érdekesek,
                      és nagyobb kirándulások alkalmával, mint megszálló helyek alkalmasak. Nyájas
                      kis faluk ezek, magyarul is beszélő tót lakossággal (hol némi élelmi szert,
                      leginkább tejet, vajat kaphatunk) jó forrásokkal és szép üde hársfákkal.
                      Ötházhuta 780 m magasságban, a másik kettővel együtt a Hasznosi-patak
                      legmagasabb forrásainál. E kis falukban természetesen nagy esemény egy-egy
                      turistacsapat elszállásolása. Összegyűlnek a fiatalok, öregek: előkerül a
                      harmonikás, és kész a bál. Akad, ki jól ismeri a régibb magyar nótákat és
                      dalolgatnak. Az elszállásolásnál az értelmes bíró nagy segítségünkre van: ahol
                      iskola van, természetesen az értelmileg kimagasló tanítóval lépünk
                      érintkezésbe.&quot; (Mátrai és gyöngyösi kalauz, Írták: Hanák, Stiller, Széky
                      - Gyöngyös, 1909)
                    </p>

                    <p>
                      Mátraszentimre és környéke mellett a Mátra egésze is csak viszonylag szűk
                      körben volt ismert. A múlt század első felének egyik útikönyve említi, hogy
                      Mikszáth Kálmán egyik írásában sorra interpellálta képviselőtársait, de még a
                      gyöngyösi sem tudta megmondani, merre is fekszik Galyatető. Az első
                      világháborút lezáró trianoni békeszerződéssel elveszett kedvelt felvidéki
                      üdülőhelyek helyett kapott mind hangsúlyosabb szerepet a Mátra. Ennek okai
                      többek között a fővároshoz, Budapesthez való közelsége, másrészt
                      viszonylagosan jó megközelíthetősége volt.
                    </p>

                    <p>
                      Az 1930-as években egy sor szállodát, szanatóriumot emeltek a Mátrában, mely
                      hamarosan az úri középosztály körében kedvelt klimatikus üdülőhellyé vált.
                      Ebben az időszakban épült a mátraházai, a kékestetői szanatórium, a galyatetői
                      Nagyszálló, hogy csak néhányat említsünk. Ugyanebben az időben emelték a mára
                      jelképpé vált Három falu templomát, illetve a galyatetői templomot.
                    </p>

                    <p>
                      A Mátrán belül is sajátos helyet foglalnak el a felső-mátrai falvak, hiszen
                      lakói - eltérően a Mátra más részeitől - döntően szlovák ajkúak voltak.
                      &quot;A csekély számú németek és tótok újabb kori telepítvényesek, az utóbbiak
                      az Ágasvártól a Galyatetőig húzódó hegygerincen három kis telepen (Hasznosi
                      Felsőhuta, Fiskalitáshuta, Ötházhuta), valamint a gerinc tövében épült
                      Szuhahután laknak, s mai napig tiszta tót nyelvű telepeket alkotnak, melyekben
                      magyar ajkú lakosság egyáltalán nincs.&quot;
                    </p>

                    <p>
                      1938-ban Szent István király halálának 900. évfordulója alkalmából
                      országszerte hatalmas ünnepségeket rendeztek, ennek részeként kapta az akkori
                      Ötházhuta a Mátraszentimre, Fiskalitáshuta a Mátraszentlászló, Óhuta a
                      Mátraszentistván nevet. Néhány évvel később épült meg az első műút Hasznos
                      felől. 1942-ben született meg a javaslat, hogy a Hasznoshoz tartozó több
                      külterületi lakott hely önálló községgé váljon. 1944 áprilisától vált
                      Mátraszentimre önálló közigazgatási egységgé. Érdekes, hogy Bagolyirtás
                      korábban Gyöngyösoroszihoz tartozott. Galyatetőt 1948-ban csatolták
                      Mátraszentimréhez.
                    </p>

                    <p>A mátraszentistváni sípark, 2013. december 29.</p>

                    <p>Víztározó a mátraszentistváni sípark hóágyúinak vízellátására</p>

                    <p>
                      Így a második világháborút követően jelentős átrendeződés következett be. A
                      szlovák-magyar lakosságcsere egyezmény keretében a Felső-Mátrában élő
                      szlovákok jelentős részét kitelepítették. A mai lakók egy része még őrzi
                      nyelvében a szlovák gyökereket. S hogy ne vesszen mindez a múlt homályába, a
                      településen élők néhány éve hagyományteremtő szándékkal indították útjára
                      mindazokat a rendezvényeket, melyeken a helybeliekkel együtt az ideérkező
                      vendégek kellemes kikapcsolódás mellett ismerkedhetnek meg a szlovák
                      hagyományokkal. Ennek jegyében évente szlovák gasztronómiai napokat,
                      falunapot, kétévente nemzetiségi találkozót rendeznek a településeken. A
                      fiatalok mellett így azok is ízelítőt kapnak a szlovák kultúra elemeiből, akik
                      vendégként érkeznek a falvakba. A rendezvények sikerét és népszerűségét
                      bizonyítja, hogy évről évre mind többen látogatnak e napokra Mátraszentimrére
                      és környékére. A község iskolájában a diákok az angol mellett szlovákul is
                      tanulnak.
                    </p>

                    <p>
                      A turizmus tömeges fellendülése az 1960-70-es évekre tehető. Bár több szálloda
                      is épült a Mátrában, Mátraszentimrén és környékén inkább kisebb panziók
                      létesültek, illetve magánházaknál bérelhettek szobát a vendégek. Az 1960-as
                      évek népszerű Panoráma útikönyv sorozata megemlíti: &quot;Azóta pedig, hogy
                      megnyílt a Turistaházakat Kezelő Vállalat Vadvirág turistaháza és mellette
                      számos vállalati és szakszervezeti üdülőház, Mátraszentimre valóságos
                      üdülőközponttá változott. … Az igényes építésű Vadvirág turistaházban táncolni
                      is lehet esténként.&quot; (Aba Iván: Budapest-Gyöngyös-Mátra) Erre az
                      időszakra jellemző, hogy a szocialista nagyvállalatok, intézmények önálló
                      pihenőházakat építettek. Ezeknek csupán töredéke maradt fenn, többségük új
                      névvel magánvállalkozásként üzemel. Sajnos, Mátraszentimre talán
                      legjellegzetesebb épülete, a Vadvirág turistaház és étterem is az enyészeté
                      lett: Befektető híján az évtizedek alatt erősen romos állapotba került
                      épületet 2013 nyarára teljes egészében lebontották.
                    </p>

                    <p>
                      Napjaink képéhez hozzátartozik, hogy a települések jelentős része havasi
                      jellegű legelő-és rétgazdálkodásra volt alkalmas. Szinte minden portán
                      szarvasmarhát, lovat és kecskéket tartottak. A családok női tagjai és a
                      gyerekek rőzsét, gombát, szedret &quot;hecsedlit&quot; azaz csipkebogyót
                      gyűjtöttek és értékesítettek. A földművelést a domborzat, a köves terméketlen
                      talaj és a közel hat hónapos tél alig tette lehetővé. Az 1970-es évek második
                      felében központi rendelkezés tiltotta meg az állattartást, a legelők, a rétek
                      bebokrosodtak, elgazosodtak. A ma turistája ezt szomorú szemmel látja, hiszen
                      az aktív állattartás tőlünk nyugatra szépen zöldellő domboldalakat láttat.
                    </p>

                    <p>
                      2007. augusztus 11-éről 12-ére virradó éjjelen leégett a falu neves étterme, a
                      Szlovák Csárda.
                    </p>

                    <h2>Nevezetességei</h2>

                    <ul>
                      <li>Szlovák Tájház</li>
                      <li>Római katolikus templom (1980-ban szentelték fel)</li>
                      <li>A II. világháború áldozatainak emléktáblája (1999-ben állították)</li>
                      <li>
                        Három falu temploma (1942-ben épült, Szentimrétől, Szentlászlótól és
                        Szentistvántól nagyjából azonos távolságra található templom)
                      </li>
                      <li>Fallóskúti Szentkút</li>
                      <li>
                        Ágasvár (A várat először 1265-ben említi oklevél. Fénykorát a 13. és a 14.
                        században élte.)
                      </li>
                      <li>Mátra Ásványház Ásványkiállítás (2015-ben nyitott)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default MatraszentimrePage;
